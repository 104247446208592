import ReactDOMServer from 'react-dom/server';
import dompurify from 'dompurify';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logo from '../../assets/zzz_logo_black.png';

const GeneratePDF = (markup, map, id) => {
    const doc = new jsPDF({
        orientation: 'p',
        unit: 'pt'
    });

    const totalPagesExp = '{total_pages_count_string}';

    const links = [];

    html2canvas(map.markup, {
        useCORS: true
    }).then(function(canvas) {
        const img = canvas.toDataURL('image/jpeg,1.0');

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const aspectRatio = canvas.width / canvas.height;
        const newWidth = pageWidth - 30;
        const newHeight = newWidth / aspectRatio;

        doc.addImage(img, 'JPEG', 15, 60, newWidth, newHeight);

        doc.autoTable({
            html: '#table',
            startY: newHeight + 60 + 15,
            headStyles: {
                fillColor: [239,240,241],
                textColor: [32,32,33]
            },
            alternateRowStyles: {
                fillColor: [250,250,251]
            },
            margin: { top: 60 },
            // didParseCell: function (data) {
            //     if (data.column.dataKey === 2) {
            //         data.get
            //         links.push(data.cell.getTextPos());
            //     }
            // },
            willDrawCell: function (data) {

                if (data.column.dataKey === 2 && data.section === 'body') {
                    doc.setTextColor(89, 167, 255);
                }
            },
            didDrawCell: function (data) {
                if (data.column.dataKey === 2) {
                    // console.log(data.cell, data.cell.x, data.cell.y);
                    console.log(data.cell.raw.dataset.lat, data.cell.raw.dataset.lng);
                    links.push({
                        x: data.cell.x,
                        y: data.cell.y,
                        width: data.cell.width,
                        height: data.cell.height,
                        lat: data.cell.raw.dataset.lat,
                        lng: data.cell.raw.dataset.lng
                    });
                }
            },
            didDrawPage: function (data) {
                // Header
                doc.setFontSize(11);
                doc.setTextColor(40);

                doc.addImage(logo, 'PNG', 15, 15, 53, 31.1)
                // doc.setFontSize(11)
                // doc.text('Location Report for ' + id, data.settings.margin.left + 60, 46);
                doc.text('Location Report for ' + id, doc.internal.pageSize.width / 2, 40, null, null, 'center');

                // Footer
                let str = 'Page ' + doc.internal.getNumberOfPages();
                // Total page number plugin only available in jspdf v1.0+
                if (typeof doc.putTotalPages === 'function') {
                    str = str + ' of ' + totalPagesExp
                }
                doc.setFontSize(10);

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                const pageSize = doc.internal.pageSize;
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                doc.text(str, data.settings.margin.left, pageHeight - 10);

                for (let i = 0; i < links.length; i++) {
                    if(links[i].lat && links[i].lng) {
                        doc.link(links[i].x, links[i].y + 10, links[i].width, links[i].height, {
                            url: `https://www.google.com/maps/search/?api=1&query=${links[i].lat},${links[i].lng}`
                        });
                    }
                }
            }
        });

        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp)
        }

        doc.save(id + '-locations-report.pdf');
        map.callback();

    });
};

export default  GeneratePDF;


export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';

export const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const USER_RESET_REQUEST_REQUEST = 'USER_RESET_REQUEST_REQUEST';
export const USER_RESET_REQUEST_SUCCESS = 'USER_RESET_REQUEST_SUCCESS';
export const USER_RESET_REQUEST_FAILURE = 'USER_RESET_REQUEST_FAILURE';
export const USER_RESET_REQUEST_RESET = 'USER_RESET_REQUEST_RESET';

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAILURE = 'USER_RESET_PASSWORD_FAILURE';
export const USER_RESET_PASSWORD_RESET = 'USER_RESET_PASSWORD_RESET';

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS';
export const USER_VERIFY_FAILURE = 'USER_VERIFY_FAILURE';

export const LOGOUT = 'LOGOUT';

export const DEVICES_GET_REQUEST = 'DEVICES_GET_REQUEST';
export const DEVICES_GET_SUCCESS = 'DEVICES_GET_SUCCESS';
export const DEVICES_GET_FAILURE = 'DEVICES_GET_FAILURE';

export const DEVICE_UPDATE_REQUEST = 'DEVICE_UPDATE_REQUEST';
export const DEVICE_UPDATE_SUCCESS = 'DEVICE_UPDATE_SUCCESS';
export const DEVICE_UPDATE_FAILURE = 'DEVICE_UPDATE_FAILURE';

export const DEVICE_ADD_VERIFIED_REQUEST = 'DEVICE_ADD_VERIFIED_REQUEST';
export const DEVICE_ADD_VERIFIED_SUCCESS = 'DEVICE_ADD_VERIFIED_SUCCESS';
export const DEVICE_ADD_VERIFIED_FAILURE = 'DEVICE_ADD_VERIFIED_FAILURE';

export const LOCATIONS_GET_REQUEST = 'LOCATIONS_GET_REQUEST';
export const LOCATIONS_GET_SUCCESS = 'LOCATIONS_GET_SUCCESS';
export const LOCATIONS_GET_FAILURE = 'LOCATIONS_GET_FAILURE';

export const PREVIOUS_LOCATIONS_GET_REQUEST = 'PREVIOUS_LOCATIONS_GET_REQUEST';
export const PREVIOUS_LOCATIONS_GET_SUCCESS = 'PREVIOUS_LOCATIONS_GET_SUCCESS';
export const PREVIOUS_LOCATIONS_GET_FAILURE = 'PREVIOUS_LOCATIONS_GET_FAILURE';

export const ADDRESS_GET_REQUEST = 'ADDRESS_GET_REQUEST';
export const ADDRESS_GET_SUCCESS = 'ADDRESS_GET_SUCCESS';
export const ADDRESS_GET_FAILURE = 'ADDRESS_GET_FAILURE';

export const UI_ACTIONBUTTON_SET_ACTIONED = 'UI_ACTIONBUTTON_SET_ACTIONED';
export const UI_ACTIONBUTTON_RESET_ACTIONED = 'UI_ACTIONBUTTON_RESET_ACTIONED';

export const FOUND_LOCATIONS_GET_REQUEST = 'FOUND_LOCATIONS_GET_REQUEST';
export const FOUND_LOCATIONS_GET_SUCCESS = 'FOUND_LOCATIONS_GET_SUCCESS';
export const FOUND_LOCATIONS_GET_FAILURE = 'FOUND_LOCATIONS_GET_FAILURE';

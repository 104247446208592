import React, {useEffect, useLayoutEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Redirect, Link, useParams } from 'react-router-dom';
import {Button, Form, Container, Divider, Message, Grid, Header, Segment} from 'semantic-ui-react';
import { authenticate, getResetToken } from "../../actions/auth";
import {resetPassword, resetRequestResetMessages} from "../../actions/user";
import styled from "styled-components";
import EmptyBoard from "../empty-board";


const DashboardLogo = styled.img`
  width: 200px;
`;

const Reset = (props) => {
    const { token } = useParams();
    const getToken = props.getToken;
    const resetMessages = props.resetMessages;

    useLayoutEffect(() => {
        getToken(token);
    }, [getToken, token]);


    // Component will unmount
    useEffect(() => {
        return () => {
            resetMessages();
        };

    }, [resetMessages]);

    let [form, setForm] = useState({
        password: {
            value: '',
            required: true,
            error: false
        },
        confirmPassword: {
            value: '',
            required: true,
            error: false
        }
    });

    if(props.auth.isAuthenticated) {
        return <Redirect to='/locations' />;
    }

    const disabled = () => {
        let disabled = false;

        if((form.password.value !== form.confirmPassword.value) || props.user.isRequestingReset) {
            disabled = true;
        }

        return disabled;
    };

    const handleSubmit = (e) => {
        const userObj = {
            password: form.password.value
        };

        e.preventDefault();
        if(props.auth.token) {
            userObj._id = props.auth._id;
            userObj.token = props.auth.token;
            props.resetPassword(userObj);
        }
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    return (
        <Container>
            <Divider hidden />

            <Grid textAlign='center' style={{height: '60vh'}} verticalAlign='middle'>
                {props.auth && props.auth.error && props.auth.token ?
                <Grid.Column >
                    <EmptyBoard message={props.auth.error} icon='frown' button={
                        <Container text fluid textAlign='center'>
                            <p>Want to create a new account or already have one? <Link to='/signup'>Sign Up</Link> or <Link to='/login'>Sign In</Link></p>
                        </Container>
                    }/>
                    </Grid.Column> :
                <Grid.Column style={{maxWidth: 420}}>
                    <DashboardLogo src='/images/zzz_logo_black.svg' /><br /><br/>

                    <Segment padded='very'>
                        <Grid columns={1} relaxed='very'>
                            <Grid.Column textAlign='left'>
                                <Header as='h1' textAlign='left'>Change your password</Header>
                                {props.user.createError ? <Message error>{props.user.createError}</Message> : null}
                                <Form onSubmit={handleSubmit}>
                                    <Form.Input
                                        required
                                        fluid
                                        placeholder='Password'
                                        type='password'
                                        onChange={change}
                                        name='password'
                                        error={form['password'].error}
                                        icon='lock'
                                        iconPosition='left'
                                    />
                                    <Form.Input
                                        required
                                        fluid
                                        placeholder='Confirm Password'
                                        type='password'
                                        onChange={change}
                                        name='confirmPassword'
                                        error={form['confirmPassword'].error}
                                        icon='lock'
                                        iconPosition='left'
                                    />

                                    <Divider hidden />
                                    <Button
                                        fluid
                                        size='large'
                                        color='blue'
                                        disabled={disabled()}
                                        type='submit'
                                    >
                                        Reset Password
                                    </Button>
                                </Form>
                            </Grid.Column>

                        </Grid>
                    </Segment>
                    <Container text fluid textAlign='center'>
                        <p><Link to='/login'>Sign In</Link></p>
                    </Container>
                </Grid.Column>}
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        resetPassword: (payload) => dispatch(resetPassword(payload)),
        getToken: (payload) => dispatch(getResetToken(payload)),
        resetMessages: () => dispatch(resetRequestResetMessages())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Reset);

import {
    AUTH_FAILURE,
    AUTH_REQUEST,
    AUTH_SUCCESS,
    CLEAR_USER_ERROR,
    DEVICE_ADD_VERIFIED_FAILURE,
    DEVICE_ADD_VERIFIED_REQUEST,
    DEVICE_ADD_VERIFIED_SUCCESS,
    GET_TOKEN_FAILURE,
    GET_TOKEN_REQUEST,
    GET_TOKEN_SUCCESS,
    // AUTH_FAILURE,
    LOGOUT,
    USER_VERIFY_FAILURE,
    USER_VERIFY_REQUEST,
    USER_VERIFY_SUCCESS
} from '../actiontypes/types';


// Check the zzz cookie exists on a refresh / initial state
const initialState = {
    _id: null,
    username: null,
    firstName: null,
    lastName: null,
    companyName: null,
    token: null,
    isAuthenticating: false,
    isAuthenticated: document.cookie.indexOf('zzzuser={') !== -1,
    tokenError: null,
    isVerifying: false,
    verifyError: null,
    verifyData: null,
    isAddingDevice: false
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case AUTH_REQUEST:
            return {
                ...state,
                isAuthenticating: true,
                error: null
            };
        case AUTH_SUCCESS:
            const { username, firstName, lastName } = payload;
            return {
                ...state,
                username,
                firstName,
                lastName,
                isAuthenticating: false,
                isAuthenticated: true,
                error: null
            };
        case AUTH_FAILURE:
            return {
                ...state,
                error: payload.message,
                isAuthenticating: false,
                isAuthenticated: false,
            };
        case LOGOUT:
            return {
                ...initialState,
                isAuthenticated: false,
                error: null
            };
        case GET_TOKEN_REQUEST:
            return {
                ...state,
                token: payload,
                error: null
            };
        case GET_TOKEN_FAILURE:
            return {
                ...state,
                error: payload
            };
        case GET_TOKEN_SUCCESS:
            return {
                ...state,
                _id: payload._id,
                username: payload.username,
                companyName: payload.accountName,
                email: payload.email,
                error: null
            };
        case USER_VERIFY_REQUEST:
            return {
                ...state,
                isVerifying: true,
                verifyError: null
            };
        case USER_VERIFY_SUCCESS:
            return {
                ...state,
                isVerifying: false,
                verifyError: null,
                verifyData: { user: payload.user, device: payload.device }
            };
        case USER_VERIFY_FAILURE:
            console.log('ERROR? ', payload);
            return {
                ...state,
                isVerifying: false,
                verifyError: payload
            };
        case DEVICE_ADD_VERIFIED_REQUEST:
            return {
                ...state,
                isAddingDevice: true
            };
        case DEVICE_ADD_VERIFIED_SUCCESS:
        case DEVICE_ADD_VERIFIED_FAILURE:
            return {
                ...state,
                isAddingDevice: false
            };
        case CLEAR_USER_ERROR:
            return {
                ...state,
                error: null,
                verifyError: null,
                verifyData: null
            };
        default: return state;
    }
};
import {
    USER_REQUEST,
    USER_SUCCESS,
    CLEAR_USER_ERROR,
    USER_RESET_REQUEST_REQUEST,
    USER_RESET_REQUEST_SUCCESS,
    USER_RESET_REQUEST_FAILURE,
    USER_RESET_REQUEST_RESET,
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_FAILURE

} from '../actiontypes/types';

import {authFailure, authSuccess} from './auth';
import api from "../utils/api";

const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const userRequest = () => {
    return {
        type: USER_REQUEST
    }
};

export const userSuccess = (payload) => {
    return {
        type: USER_SUCCESS,
        payload
    }
};

export const userGet = () => {
    return (dispatch) => {
        dispatch(userRequest());

        return fetch(`${ZZZAPI}/api/user`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                } else {
                    dispatch(userSuccess(json))
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(authFailure(err))
            });
    }
};

// Password Reset

export const userRequestResetRequest = () => {
    return {
        type: USER_RESET_REQUEST_REQUEST
    }
};

export const userRequestResetSuccess = (payload) => {
    return {
        type: USER_RESET_REQUEST_SUCCESS,
        payload
    }
};

export const userRequestResetFailed = (payload) => {
    return {
        type: USER_RESET_REQUEST_FAILURE,
        payload
    }
};

export const userRequestReset = (payload) => {
    return (dispatch) => {
        dispatch(userRequestResetRequest());

        return fetch(`${ZZZAPI}/api/user/requestreset`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(userRequestResetSuccess(json))
            })
            .catch(err => dispatch(userRequestResetFailed(err.message)));
    }
};

export const resetRequestResetMessages = () => {
    return {
        type: USER_RESET_REQUEST_RESET
    }
};

export const resetPasswordRequest = () => {
    return {
        type: USER_RESET_PASSWORD_REQUEST
    }
};

export const resetPasswordFailure = (payload) => {
    return {
        type: USER_RESET_PASSWORD_FAILURE,
        payload
    }
};

export const resetPasswordSuccess = (payload) => {
    return (dispatch) => {
        const {username, firstName, lastName} = payload;

        dispatch({
            type: USER_RESET_PASSWORD_SUCCESS,
            payload
        });

        return dispatch(authSuccess({ username, firstName, lastName}));
    }
};

export const resetPassword = (payload) => {
    return (dispatch) => {
        dispatch(resetPasswordRequest());

        const success = (obj) => {
            const userObj = {
                username: obj.username,
                firstName: obj.firstName,
                lastName: obj.lastName
            };

            console.log('SETTING COOKIE AT resetPassword');

            // set local cookie once created
            document.cookie = 'zzzuser=' + JSON.stringify(userObj) + ';path=/;expires=' + obj.cookieExpDate;

            dispatch(resetPasswordSuccess(obj));
        };

        const failure = (err) => {
            dispatch(resetPasswordFailure(err.message));
        };

        return api(
            `${ZZZAPI}/api/user/reset`,
            'PUT',
            {},
            success,
            failure,
            payload
        );
    }
};

export const clearError = () => {
    return {
        type: CLEAR_USER_ERROR
    }
};
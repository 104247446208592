import React, {useState, useEffect} from 'react';
import Moment from "moment-timezone";

/**
 * Get the countdown for the next nearest time
 * Pass in as an array ['08:40', '21:30']
 * @param props
 * @returns {*}
 * @constructor
 */
const Countdown = (props) => {

    const [countdown, setCountdown] = useState(null);
    const [ timer, setTimer ] = useState(null);

    const getNextTime = (times) => {
        const durations = times.map(time => {
            // Calculate duration based on UTC
            const start = Moment.utc(new Date(), "h:mm a");
            const end = Moment.utc(time, "h:mm a");

            const duration = Moment.duration(end.diff(start));

            return {time, duration: duration.asMinutes()};
        }).filter(duration => {
            return duration.duration;
        });

        if (durations.length > 0) {
            const sorted = durations.sort((a, b) => (a.duration > b.duration) ? 1 : -1);
            const resorted = sorted.filter(duration => duration.duration > 0).concat(sorted.filter(duration => duration.duration <= 0));
            return { time: resorted[0].time, duration: resorted[0].duration };
        }

        return null;
    };

    const getDuration = (timeObj) => {
        const futureDate = timeObj.duration <= 0 ? Moment.utc(timeObj.time, "h:mm a").add(1, 'days') : Moment.utc(timeObj.time, "h:mm a");
        const start = Moment.utc(new Date(), "h:mm a");

        return Moment.duration(futureDate.diff(start)).asMilliseconds();
    };

    const getCountdown = (duration) => {
        let d = Moment.duration(duration).days();
        let h = Moment.duration(duration).hours();
        let m = Moment.duration(duration).minutes();
        let s = Moment.duration(duration).seconds();

        d = d < 10 && d > 0 ? '0' + d : d;
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;

        return { d, h, m, s };
    };

    useEffect(() => {
        if(!timer && props.times && props.times.length > 0) {
            const nextTime = getNextTime(props.times);
            const duration = getDuration(nextTime);
            const interval = 1000;

            const { d, h, m, s } = getCountdown(duration);
            if(!countdown) setCountdown(`${d !== 0 ? d + ':' : ''}${h}:${m}:${s}`);

            const go = setInterval(() => {
                const res = getNextTime(props.times);
                const duration = getDuration(res);
                const { d, h, m, s } = getCountdown(duration);
                if(!countdown) setCountdown(`${d !== 0 ? d + ':' : ''}${h}:${m}:${s}`);
            }, interval);

            setTimer(go);
        }
    }, [countdown, setCountdown, timer, props.times]);

    // Component will unmount
    useEffect(() => {
        return () => {
            if(timer) clearInterval(timer);
        };

    }, [timer]);

    // Get duration
        // If duration is in the past, create datetime by adding a day
    // Get duration between future day and now
    // Display days, hours, minutes, seconds until future time

    return (
        <span style={props.styles}>
            {countdown ? countdown : '00:00:00'}
        </span>
    );
};

export default Countdown;

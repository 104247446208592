/**
 *
 * @param url
 * @param method
 * @param extraHeaders
 * @param success
 * @param failure
 * @param body
 * @returns {Promise<any | void | never>}
 */
const api = (url, method, extraHeaders, success, failure, body = null) => {
    const fetchInit = {
        crossDomain: true,
        method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache': 'no-cache',
            ...extraHeaders
        },
        credentials: 'include'
    };

    if(method === 'POST' || method === 'PUT') {
        fetchInit.body = JSON.stringify(body || {});
    }

    return fetch(url, fetchInit)
        .then(response => {
                return response.json()
            },
            error => console.log('An error occurred: ', error))
        .then(json => {
            if(json.error) throw new Error(json.message);
            success(json)
        })
        .catch(err => failure(err));
};

export default api;
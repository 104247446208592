import {
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_FAILURE,
    LOGOUT,
    GET_TOKEN_REQUEST,
    GET_TOKEN_SUCCESS,
    GET_TOKEN_FAILURE,
    USER_VERIFY_REQUEST,
    USER_VERIFY_SUCCESS,
    USER_VERIFY_FAILURE,
    DEVICE_ADD_VERIFIED_REQUEST,
    DEVICE_ADD_VERIFIED_SUCCESS
} from '../actiontypes/types';
import api from '../utils/api';

const ZZZAPI = process.env.REACT_APP_ZZZAPI;
// console.log('ZZZAPI', ZZZAPI);

const authRequest = () => {
    return {
        type: AUTH_REQUEST
    }
};

export const authSuccess = (payload) => {
    return {
        type: AUTH_SUCCESS,
        payload
    }
};

export const authFailure = (payload) => {
    return {
        type: AUTH_FAILURE,
        payload
    }
};

export const authenticate = (payload) => {
    return (dispatch) => {
        dispatch(authRequest());

        const success = (obj) => {
            const userObj = {
                username: obj.username,
                firstName: obj.firstName,
                lastName: obj.lastName
            };

            console.log('SETTING COOKIE AT authenticate');
            // set local cookie once authenticated
            document.cookie = 'zzzuser=' + JSON.stringify(userObj) + '; expires=' + obj.cookieExpDate + '; path=/;';

            dispatch(authSuccess(userObj));
        };

        const failure = (err) => {
            dispatch(authFailure(err))
        };

        return api(
            `${ZZZAPI}/api/authenticate`,
            'POST',
            {},
            success,
            failure,
            {
                'password': payload.password,
                'username': payload.username,
                'appName': payload.appName,
                'role': payload.role
            }
        );
    }
};

export const logoutRequest = () => {
    return {
        type: LOGOUT
    }
};

export const logout = () => {
    return (dispatch) => {
        dispatch(logoutRequest());

        return fetch(`${ZZZAPI}/api/logout`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include'
            });
    }
};

const tokenRequest = (payload) => {
    return {
        type: GET_TOKEN_REQUEST,
        payload
    }
};

export const tokenSuccess = (payload) => {
    return {
        type: GET_TOKEN_SUCCESS,
        payload
    }
};

export const tokenFailure = (payload) => {
    return {
        type: GET_TOKEN_FAILURE,
        payload
    }
};

export const getToken = (payload) => {
    return (dispatch) => {
        dispatch(tokenRequest(payload));

        // Don't continue the request without a payload
        if(!payload) return;

        return fetch(`${ZZZAPI}/api/user/token/${payload}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                }
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(tokenSuccess(json))
            })
            .catch(err => {
                if(err) dispatch(tokenFailure(err.message));
            });
    }
};

export const getResetToken = (payload) => {
    return (dispatch) => {
        dispatch(tokenRequest(payload));

        // Don't continue the request without a payload
        if(!payload) return;

        return fetch(`${ZZZAPI}/api/user/resettoken/${payload}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                }
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(tokenSuccess(json))
            })
            .catch(err => {
                if(err) dispatch(tokenFailure(err.message));
            });
    }
};


const verifyUserRequest = () => {
    return {
        type: USER_VERIFY_REQUEST
    }
};

export const verifyUserSuccess = (payload) => {
    return {
        type: USER_VERIFY_SUCCESS,
        payload
    }
};

export const verifyUserFailure = (payload) => {
    return {
        type: USER_VERIFY_FAILURE,
        payload
    }
};

export const verifyUser = (payload) => {
    return (dispatch) => {
        dispatch(verifyUserRequest());

        // Don't continue the request without a payload
        if(!payload) return;

        const body = {
            email: payload.email,
            code: payload.securityCode
        };

        return fetch(`${ZZZAPI}/api/user/verify`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(body)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(verifyUserSuccess(json))
            })
            .catch(err => {
                if(err) dispatch(verifyUserFailure(err.message));
            });
    }
};
const addVerifiedDeviceRequest = () => {
    return {
        type: DEVICE_ADD_VERIFIED_REQUEST
    }
};

export const addVerifiedDeviceSuccess = (payload) => {
    return {
        type: DEVICE_ADD_VERIFIED_SUCCESS,
        payload
    }
};

export const addVerifiedDeviceFailure = (payload) => {
    return {
        type: DEVICE_ADD_VERIFIED_SUCCESS,
        payload
    }
};

export const addVerifiedDevice = (payload) => {
    return (dispatch) => {
        dispatch(addVerifiedDeviceRequest());

        // Don't continue the request without a payload
        if(!payload) return;

        const body = {
            accountId: payload.accountId,
            deviceId: payload._id,
            crimeRef: payload.crimeRef,
            userLoc: payload.userLoc
        };

        return fetch(`${ZZZAPI}/api/devices/addlocator`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(body)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) {
                    throw new Error(json.message);
                }
                dispatch(addVerifiedDeviceSuccess(json))
            })
            .catch(err => {
                if(err) dispatch(addVerifiedDeviceFailure(err.message));
            });
    }
};

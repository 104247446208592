import {
    DEVICES_GET_REQUEST,
    DEVICES_GET_SUCCESS,
    DEVICE_UPDATE_REQUEST,
    DEVICE_UPDATE_SUCCESS,
    LOCATIONS_GET_SUCCESS,
    ADDRESS_GET_SUCCESS,
    ADDRESS_GET_REQUEST,
    ADDRESS_GET_FAILURE,
    PREVIOUS_LOCATIONS_GET_SUCCESS,
    PREVIOUS_LOCATIONS_GET_REQUEST,
    PREVIOUS_LOCATIONS_GET_FAILURE, FOUND_LOCATIONS_GET_SUCCESS, FOUND_LOCATIONS_GET_REQUEST
} from '../actiontypes/types';

const initialState = {
    devices: [],
    locationsView: [],
    foundLocationsView: [],
    previousLocations: {},
    tracked: [],
    lastWakeups: [],
    isLoadingDevices: true,
    isUpdatingTimes: false,
    isUpdatingDevice: null,
    isGettingAddress: false,
    isGettingLastWakeups: false,
    isGettingPreviousLocations: false,
    error: null
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case ADDRESS_GET_REQUEST:
            return {
                ...state,
                isGettingAddress: true
            };
        case ADDRESS_GET_FAILURE:
            return {
                ...state,
                isGettingAddress: true
            };
        case ADDRESS_GET_SUCCESS:
            return {
                ...state,
                isGettingAddress: false,
                locationsView: state.locationsView.map(view => {
                    return view._id === payload._id ? { ...view, address: payload.response.results[0].formatted_address } : view;
                })
            };
        case DEVICES_GET_REQUEST:
            return {
                ...state,
                isLoadingDevices: true
            };
        case DEVICES_GET_SUCCESS:
            return {
                ...state,
                devices: payload.units,
                isLoadingDevices: false
            };
        case DEVICE_UPDATE_REQUEST:
            return {
                ...state,
                isUpdatingDevice: payload
            };
        case DEVICE_UPDATE_SUCCESS:
            // console.log(payload);

            const updatedDevices = payload.data.map(item => {
                const updated = {};

                if(item.name || item.name === '') updated.name = item.name;
                if(item.pendingOperatingMode) updated.pendingOperatingMode = item.pendingOperatingMode;

                return { _id: item._id, updated };
            });

            const newLocationsView = state.locationsView.map((device) => {
                const found = updatedDevices.find(updatedDevice => device._id === updatedDevice._id);
                return found ? { ...device, data: {...device.data, ...found.updated} } : device;
            });

            return {
                ...state,
                locationsView: newLocationsView,
                isUpdatingDevice: null
            };
        case LOCATIONS_GET_SUCCESS:
            const locationsView = [];

            payload.devices.forEach(dev => {
                const locationObj = { _id: dev._id };
                locationObj.data = dev;
                locationObj.name = dev.name;
                locationObj.wakeups = payload.locations.filter(device => device._id === dev._id);
                locationsView.push(locationObj);
            });

            if(JSON.stringify(locationsView) !== JSON.stringify(state.locationsView)) {
                return {
                    ...state,
                    locationsView: locationsView,
                    isLoadingDevices: false
                };
            }

            return { ...state, isLoadingDevices: false }
        case FOUND_LOCATIONS_GET_REQUEST:
            return {...state, isLoadingDevices:  true};
        case FOUND_LOCATIONS_GET_SUCCESS:
            const foundLocationsView = [];

            payload.devices.forEach(dev => {
                const locationObj = { _id: dev._id };
                locationObj.data = dev;
                locationObj.name = dev.deviceData.name;
                locationObj.wakeups = payload.locations.filter(device => device._id === dev.deviceData._id);
                foundLocationsView.push(locationObj);
            });

            if(JSON.stringify(foundLocationsView) !== JSON.stringify(state.foundLocationsView)) {
                return {
                    ...state,
                    foundLocationsView: foundLocationsView,
                    isLoadingDevices: false
                };
            }

            return { ...state, isLoadingDevices: false }
        case PREVIOUS_LOCATIONS_GET_REQUEST:
            return {
                ...state,
                isGettingPreviousLocations: true
            };
        case PREVIOUS_LOCATIONS_GET_SUCCESS:
            return {
                ...state,
                isGettingPreviousLocations: false,
                previousLocations: { ...state.previousLocations, [payload.deviceId]: { wakeups: payload.previouslocations } }
            };
        case PREVIOUS_LOCATIONS_GET_FAILURE:
            return {
                ...state,
                isGettingPreviousLocations: false,
                error: payload
            };
        default: return { ...state, error: null };
    }
};

import {
    USER_REQUEST,
    USER_SUCCESS,
    USER_FAILURE,
    CLEAR_USER_ERROR,
    USER_RESET_REQUEST_REQUEST,
    USER_RESET_REQUEST_SUCCESS,
    USER_RESET_REQUEST_FAILURE,
    USER_RESET_REQUEST_RESET,
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_FAILURE
} from '../actiontypes/types';

const initialState = {
    username: null,
    firstName: null,
    companyName: null,
    lastName: null,
    address1: null,
    address2: null,
    address3: null,
    address4: null,
    postcode: null,
    country: null,
    isParent: null,
    roles: null,
    email: null,
    contactNumber: null,
    alertsNumber: null,
    isLoadingUser: true,
    error: null,
    isRequestingReset: false,
    isRequestedReset: null,
    resetRequestError: null
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case USER_REQUEST:
            return {
                ...state,
                isLoadingUser: true,
                error: null
            };
        case USER_SUCCESS:
            const {
                _id,
                username,
                firstName,
                companyName,
                lastName,
                address1,
                address2,
                address3,
                address4,
                postcode,
                country,
                isParent,
                roles,
                email,
                contactNumber,
                alertsNumber
            } = payload;
            return {
                ...state,
                _id,
                username,
                firstName,
                companyName,
                lastName,
                address1,
                address2,
                address3,
                address4,
                postcode,
                country,
                isParent,
                roles,
                email,
                contactNumber,
                alertsNumber,
                isLoadingUser: false,
                error: null
            };
        case USER_FAILURE:
            return {
                ...state,
                error: payload
            };
        case USER_RESET_REQUEST_REQUEST:
            return {
                ...state,
                isRequestingReset: true
            };
        case USER_RESET_REQUEST_SUCCESS:
            return {
                ...state,
                isRequestingReset: false,
                isRequestedReset: 'Email with link sent!',
                resetRequestError: null
            };
        case USER_RESET_REQUEST_FAILURE:
            return {
                ...state,
                isRequestingReset: false,
                isRequestedReset: false,
                resetRequestError: payload,
            };
        case USER_RESET_REQUEST_RESET:
            return {
                ...state,
                isRequestingReset: false,
                isRequestedReset: null,
                resetRequestError: null
            };
        case USER_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                isRequestingReset: true
            };
        case USER_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isRequestingReset: false,
                isRequestedReset: null,
                resetRequestError: null
            };
        case USER_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                isRequestingReset: false,
                isRequestedReset: false,
                resetRequestError: payload,
            };
        case CLEAR_USER_ERROR:
        default: return { ...state, error: null, createError: null };
    }
};
import {Button, Header, Icon, Modal} from "semantic-ui-react";
import React from "react";

const BaseModal = (props) => {
    const modalHeader = (
        <Modal.Header>
            <Header color={props.color} as='h3'>
                <Icon circular name={props.icon} inverted color={props.color} />{props.title}
            </Header>
        </Modal.Header>
    );

    const modalContent = (
        <Modal.Content>
            {props.children}
        </Modal.Content>
    );

    return props.trigger ? (
        <Modal
            trigger={
                <Button
                    color={props.color}
                    icon={props.icon}
                    content={props.content}
                    labelPosition={props.labelPosition}
                    size={props.buttonSize}
                />
            }
            size={props.size || 'tiny'}
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
        >
            {modalHeader}
            {modalContent}
        </Modal>
    ) : (
        <Modal
            size={props.size || 'tiny'}
            open={props.open}
            onClose={props.onClose}
            onOpen={props.onOpen}
        >
            {props.title || props.icon ?  modalHeader : null}
            {modalContent}
        </Modal>
    );
};

export default BaseModal;
import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {Button, Form, Grid, Header, Container, Message, Segment, Divider } from 'semantic-ui-react';
import { authenticate } from '../../actions/auth';

const DashboardLogo = styled.img`
  width: 200px;
`;

const Login = (props) => {
    let [user, setUser] = useState({ appName: 'Locator', role: 'locator_user' });
    let username = user.username ? user.username : '';
    let password = user.password ? user.password : '';

    const change = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value})
    };

    const submit = (e) => {
        e.preventDefault();
        props.login(user);
    };

    if(props.auth.isAuthenticated) {
        return <Redirect to='/locations' />;
    }

    return (
        <Container>

            <Grid textAlign='center' style={{height: '60vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 420}}>

                    <DashboardLogo src='/images/zzz_logo_black.svg' /><br /><br/>
                    <Header as='h2' color='black' textAlign='center'>
                        Locator Application
                    </Header>
                    <Segment padded='very'>
                        <Grid columns={1} relaxed='very' >
                            <Grid.Column>
                                <Header as='h1' textAlign='left'>Sign In</Header>
                                {props.auth.error ? <Message error>{props.auth.error}</Message> : null}
                                <Form>
                                    <Form.Input
                                        fluid
                                        icon='user'
                                        iconPosition='left'
                                        placeholder='Username'
                                        onChange={change}
                                        value={username}
                                        name='username'
                                    />
                                    <Form.Input
                                        fluid
                                        icon='lock'
                                        iconPosition='left'
                                        placeholder='Password'
                                        type='password'
                                        onChange={change}
                                        value={password}
                                        name='password'
                                    />
                                    <Divider hidden />
                                    <Button fluid size='large' color='blue' onClick={submit}>
                                        Login
                                    </Button>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid>
        </Container>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {auth: state.auth}
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { useState, useEffect } from 'react';
import { Provider, connect } from 'react-redux';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Redirect, withRouter, Switch } from "react-router-dom";
import Locations from '../locations';
import Login from '../login';
import MenuBar from '../menu-bar';
import ResetRequest from '../reset-request';
import Reset from '../reset';
import {Container, Divider} from "semantic-ui-react";
import { userGet } from "../../actions/user";
import Cookies from "../../utils/cookies";

const version = process.env.REACT_APP_VERSION;

console.log('version: ', version);

const ContainerThemed = styled(Container)`
  &&& {
    background-color: ${props => props.theme.colours.bg};
    height: 100vh;
    overflow-x: hidden;
  }
`;

const Root = ({ store }, props) => {
    // let es;
    const [ userGot, setUserGot ] = useState(false);

    useEffect(() => {
        if(store.getState().auth.isAuthenticated && !userGot) {
            store.dispatch(userGet());
            setUserGot(true);
        }
    });

    return (
        <Provider store={store}>
            <Router>
                <ContainerThemed fluid>
                    <MenuBar />
                    <Switch>
                        <ProtectedRoute path='/locations' component={Locations} />
                        <Route exact path='/' component={Login} />
                        <Route path='/login' component={Login} />
                        <Route exact path='/logout' render={() => {
                            console.log('Clearing Cookies!');
                            Cookies.delete('zzzuser');
                            Cookies.delete('zzz');

                            return <Redirect to='login' />;
                        }} />
                        <Route exact path='/resetrequest' component={ResetRequest} />
                        <Route exact path='/reset' component={Login} />
                        <Route exact path='/reset/:token' component={Reset} />
                        <ProtectedRoute exact path='/locations' component={Locations} isParent={store.getState().user.isParent} />
                    </Switch>
                    <Divider section />
                    <Container textAlign='right'>Version {(version || '0.0.0').substring(0,3)} ({version || '0.0.0.0'})</Container>
                </ContainerThemed>
            </Router>
        </Provider>
    )
};

const _ProtectedRoute = ({ component: Component, path, redirect, auth, ...rest }) => {
    if(!auth.isAuthenticated) {
        Cookies.delete('zzzuser');
        Cookies.delete('zzz');
        return <Redirect from='/locations' to='/login' />
    }

    return <Route path={path} component={Component} />;
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        // login: (payload) => dispatch(authenticate(payload))
        userGet: () => dispatch(userGet()),
    }
};

const ProtectedRoute = withRouter(connect(mapStateToProps, mapDispatchToProps)(_ProtectedRoute));

export default connect(mapStateToProps, mapDispatchToProps)(Root);

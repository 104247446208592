import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import {Button, Form, Container, Divider, Message, Grid, Header, Segment} from 'semantic-ui-react';
import { authenticate } from "../../actions/auth";
import {resetRequestResetMessages, userRequestReset} from "../../actions/user";
import styled from "styled-components";


const DashboardLogo = styled.img`
  width: 200px;
`;

const ResetRequest = (props) => {
    const resetMessages = props.resetMessages;
    let [form, setForm] = useState({
        email: {
            value: '',
            required: true,
            error: false
        }
    });

    // Component will unmount
    useEffect(() => {
        return () => {
            resetMessages();
        };

    }, [resetMessages]);

    if(props.auth.isAuthenticated) {
        return <Redirect to='/locations' />;
    }

    const disabled = () => {
        let disabled = false;

        // TODO: Email validation
        if(!form.email.value || props.user.isRequestingReset) {
            disabled = true;
        }

        return disabled;
    };

    const handleSubmit = (e) => {
        const userObj = {
            email: form.email.value,
        };

        e.preventDefault();
        props.userRequestReset(userObj);
    };

    const change = (e) => {
        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: e.target.value
            }
        });
    };

    return (
        <Container>
            <Divider hidden />

            <Grid textAlign='center' style={{height: '60vh'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 420}}>
                    <DashboardLogo src='/images/zzz_logo_black.svg' /><br /><br/>

                    <Segment padded='very'>
                        <Grid columns={1} relaxed='very'>
                            <Grid.Column textAlign='left'>
                                <Header as='h1' textAlign='left'>Forgotten your password?</Header>
                                {props.user.resetRequestError ? <Message error>{props.user.resetRequestError}</Message> : null}
                                {props.user.isRequestedReset ? <Message success>{props.user.isRequestedReset}</Message> : null}
                                <Form onSubmit={handleSubmit}>
                                    <p>Tell us your registered email and we'll send you a reset link.</p>
                                    <p style={{fontWeight: 'bold'}}>My email address is:</p>
                                    <Form.Input
                                        required
                                        fluid
                                        placeholder='Email Address'
                                        type='text'
                                        onChange={change}
                                        name='email'
                                        value={form['email'].value}
                                        error={form['email'].error}
                                    />
                                    <Divider hidden />
                                    <Button
                                        fluid
                                        size='large'
                                        color='blue'
                                        disabled={disabled()}
                                        submit={handleSubmit}
                                        loading={props.user.isRequestingReset}
                                    >
                                        Send the Link
                                    </Button>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <Container text fluid textAlign='center'>
                        <p><Link to='/login'>Back to Sign In</Link></p>
                    </Container>
                </Grid.Column>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        userRequestReset: (payload) => dispatch(userRequestReset(payload)),
        resetMessages: () => dispatch(resetRequestResetMessages())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetRequest);

import React, {useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import {Loader} from "semantic-ui-react";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const plotPoint = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: 'red',
    fillOpacity: 1,
    scale: 0.035,
    anchor: {x: 300, y: 200},
    labelOrigin: { x: 250, y: 260}
};

let google = null;

const googleMapsLibraries = ['visualization', 'places'];

const onError = err => console.log('onError: ', err);


/**
 * The Map Component
 * @param props
 * @returns {*}
 * @constructor
 */
const Map = (props) => {
    const [ center, setCenter ] = useState({lat: props.lat || props.center.lat, lng: props.lng || props.center.lng });
    const [ stroke, setStroke ] = useState({ weight: 2, direction: 1});
    const [ mapOptions, setMapOptions ] = useState(null);
    const [ mapOptionsSet, setMapOptionsSet ] = useState(true);

    useEffect(() => {
        if(props.centered && (props.lat !== center.lat && props.lng !== center.lng)) {
            setCenter({ lat: props.lat, lng: props.lng });
            props.setCentered(false);
        }

        if(props.isTracking && props.lat !== center.lat && props.lng !== center.lng) {
            setCenter({ lat: props.lat, lng: props.lng });
            props.setCentered(false);
        }

        if(props.disableMapUI) {
            setMapOptions({
                disableDefaultUI: props.disableMapUI
            });
            setMapOptionsSet(false);
        }

        if(!props.disableMapUI && !mapOptionsSet) {
            setMapOptions({ disableDefaultUI: false });
            setMapOptionsSet(true);
        }

    }, [props, center.lat, center.lng]);

    const carSVG = {
        path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
        fillColor: '#37B5EA',
        fillOpacity: 1,
        scale: 0.03,
        anchor: {x: 300, y: 200},
        labelOrigin: { x: 250, y: 260},
        strokeColor: "rgb(55,181,234, 0.5)",
        strokeOpacity: 1,
        strokeWeight: 15 //stroke.weight
    };

    const trackingCarSVG = {
        path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
        fillColor: 'red',
        fillOpacity: 1,
        scale: 0.03,
        anchor: {x: 300, y: 200},
        labelOrigin: { x: 250, y: 260},
        strokeColor: 'rgb(244,14,3,0.5)',
        strokeOpacity: 1,
        strokeWeight: 15 //stroke.weight
    };

    useEffect(() => {
        // let radius = 25;
        const rMax = 4;
        const rMin = 2;
        // let direction = 1;


        // const radiusInterval = setInterval(() => {
        //     // Prevent pulsing animation in 'geofence' map mode, as drawing and animations don't mix well in google maps
        //     if ((props.mapType !== 'geofences') && ((stroke.weight > rMax) || (stroke.weight < rMin))) {
        //         setStroke({ ...stroke, direction: stroke.direction *= -1});
        //     }
        //     if(props.mapType !== 'geofences') {
        //         setStroke({ ...stroke, weight: stroke.weight + (stroke.direction * 0.25) });
        //     }
        // }, 120);
        // return () => clearInterval(radiusInterval);


    }, [stroke, props.mapType]);

    const onLoad = () => {
        google = window.google;
    };


    return <LoadScript
                id='script-loader'
                googleMapsApiKey={API_KEY}
                onLoad={onLoad}
                onError={onError}
                libraries={googleMapsLibraries}
                preventGoogleFontsLoading
            >
                <div id='map' className='map' style={{ height: `100%`}}>
                    <div className='map-container' style={{ height: `100%`}}>
                        {google ? <GoogleMap
                            id='google-map'
                            mapContainerStyle={{ height: `100%`}}
                            scaleControl={true}
                            zoom={props.defaultZoom}
                            center={center}
                            clickableIcons={false}
                            // options={{
                            //     disableDefaultUI: disableUI,
                            // }}
                            options={mapOptions}
                        >

                            {props.previousLocations.length > 0 && (
                                props.previousLocations.map((marker, i) => (
                                    <div
                                        key={'prev-marker-'+i}
                                    >
                                        <Marker
                                            label={{ text: marker.text || null, color: 'white', fontSize: '8px', fontWeight: 'bold' }}
                                            position={{ lat: marker.lat, lng: marker.lng }}
                                            icon={plotPoint}
                                            zIndex={50}

                                        >
                                        </Marker>
                                    </div>
                                ))
                            )}

                            {/* Show the markers */}
                            {props.markers.length > 0 && (
                                props.markers.map((marker, i) => (
                                    <div key={'marker-'+i}>
                                        {/*<Circle*/}
                                        {/*    key={'marker-circle-'+ i}*/}
                                        {/*    center={{ lat: marker.lat, lng: marker.lng }}*/}
                                        {/*    options={{*/}
                                        {/*        strokeColor: "lightblue",*/}
                                        {/*        strokeOpacity: 1,*/}
                                        {/*        strokeWeight: 1,*/}
                                        {/*        fillColor: "lightblue",*/}
                                        {/*        fillOpacity: 0.4,*/}
                                        {/*        editable: false,*/}
                                        {/*        draggable: false,*/}
                                        {/*        clickable: false,*/}
                                        {/*        radius: 25 * marker.hdop,*/}
                                        {/*        index: i*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        <Marker
                                            key={'marker-marker'+i}
                                            position={{ lat: marker.lat, lng: marker.lng }}
                                            icon={marker.tracking ? trackingCarSVG : carSVG}
                                            zIndex={150}
                                        >
                                            {/*({showInfo.show && showInfo.id === 'marker-' + i ?*/}
                                            {/*<Popup*/}
                                            {/*    anchorPosition={{ lat: marker.lat, lng: marker.lng }}*/}
                                            {/*    markerPixelOffset={{ x: 0, y: -62 }}*/}
                                            {/*    content={marker.content}*/}
                                            {/*/>*/}
                                            {/*: null}*/}
                                        </Marker>
                                    </div>))
                            )}

                        </GoogleMap> :
                            <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                <Loader active size='big' inline='centered'>Loading</Loader>
                            </div> }
                    </div>
                </div>
            </LoadScript>
};

export default Map;

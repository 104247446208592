import {
    DEVICES_GET_REQUEST,
    DEVICES_GET_SUCCESS,
    LOCATIONS_GET_FAILURE,
    LOCATIONS_GET_REQUEST,
    LOCATIONS_GET_SUCCESS,
    ADDRESS_GET_SUCCESS,
    ADDRESS_GET_REQUEST,
    ADDRESS_GET_FAILURE,
    PREVIOUS_LOCATIONS_GET_REQUEST,
    PREVIOUS_LOCATIONS_GET_SUCCESS,
    PREVIOUS_LOCATIONS_GET_FAILURE,
    DEVICE_UPDATE_REQUEST,
    DEVICE_UPDATE_SUCCESS,
    FOUND_LOCATIONS_GET_REQUEST,
    FOUND_LOCATIONS_GET_SUCCESS,
    FOUND_LOCATIONS_GET_FAILURE
} from '../actiontypes/types';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const ZZZAPI = process.env.REACT_APP_ZZZAPI;

export const devicesGetRequest = () => {
    return {
        type: DEVICES_GET_REQUEST
    }
};

export const devicesGetSuccess = (payload) => {
    return {
        type: DEVICES_GET_SUCCESS,
        payload
    }
};

export const devicesGet = () => {
    return (dispatch) => {
        dispatch(devicesGetRequest());

        // return fetch(`${ZZZAPI}/api/devices`,
        //     {
        //         method: 'GET',
        //         headers: {
        //             'Accept': 'application/json',
        //             // 'Content-Type': 'application/json',
        //             // 'Cache': 'no-cache'
        //         },
        //         credentials: 'include'
        //     })
        //     .then(response => {
        //             if(response.status === 200) {
        //                 return response.json();
        //             }
        //         },
        //         error => console.log('An error occurred: ', error))
        //     .then(json => dispatch(devicesGetSuccess(json)));
    }
};

export const locationsGetRequest = () => {
    return {
        type: LOCATIONS_GET_REQUEST
    }
};

export const locationsGetSuccess = (payload) => {
    return {
        type: LOCATIONS_GET_SUCCESS,
        payload
    }
};

export const locationsGetFailure = (payload) => {
    return {
        type: LOCATIONS_GET_FAILURE,
        payload
    }
};

export const locationsGet = (payload = {}) => {
    return (dispatch) => {
        dispatch(locationsGetRequest());

        const queryParams = Object.keys(payload);
        let queryString = '';

        if(queryParams.length > 0) {
            queryString += '?';
            queryParams.forEach(query => {
                if(queryString !== '?') queryString += '&';
                queryString += `${query}=${payload[query]}`
            })
        }

        return fetch(`${ZZZAPI}/api/locatorlocations`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(locationsGetSuccess(json))
            })
            .catch(err => dispatch(locationsGetFailure(err.message)));
    }
};
export const previousLocationsGetRequest = () => {
    return {
        type: PREVIOUS_LOCATIONS_GET_REQUEST
    }
};

export const previousLocationsGetSuccess = (payload) => {
    return {
        type: PREVIOUS_LOCATIONS_GET_SUCCESS,
        payload
    }
};

export const previousLocationsGetFailure = (payload) => {
    return {
        type: PREVIOUS_LOCATIONS_GET_FAILURE,
        payload
    }
};

export const previousLocationsGet = (payload = {}) => {
    return (dispatch) => {
        dispatch(previousLocationsGetRequest());

        const queryParams = Object.keys(payload);
        let queryString = '';

        if(queryParams.length > 0) {
            queryString += '?';
            queryParams.forEach(query => {
                if(queryString !== '?') queryString += '&';
                queryString += `${query}=${payload[query]}`
            })
        }

        return fetch(`${ZZZAPI}/api/previouslocations${queryString}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(previousLocationsGetSuccess(json))
            })
            .catch(err => dispatch(previousLocationsGetFailure(err.message)));
    }
};

export const addressGetRequest = () => {
    return {
        type: ADDRESS_GET_REQUEST
    }
};

export const addressGetFailure = (payload) => {
    return {
        type: ADDRESS_GET_FAILURE,
        payload
    }
};
export const addressGetSuccess = (payload) => {
    return {
        type: ADDRESS_GET_SUCCESS,
        payload
    }
};

export const addressGet = (payload) => {
    return (dispatch) => {
        dispatch(addressGetRequest());

        // return fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${payload.latitude},${payload.longitude}&key=${GOOGLE_API_KEY}`,
        //     {
        //         method: 'GET',
        //         headers: {
        //             'Accept': 'application/json'
        //         }
        //     })
        //     .then(response => response.json())
        //     .then(json => {
        //         if(json.error) throw new Error(json.message);
        //         dispatch(addressGetSuccess({ response: json, _id: payload._id}));
        //     })
        //     .catch(err => {
        //         console.log(err);
        //         dispatch(addressGetFailure(err))
        //     })
    }
};

export const deviceUpdateRequest = (payload) => {
    return {
        type: DEVICE_UPDATE_REQUEST,
        payload
    }
};

export const deviceUpdateSuccess = (payload) => {
    return {
        type: DEVICE_UPDATE_SUCCESS,
        payload
    }
};

export const deviceSetOperMode = (payload) => {
    return (dispatch) => {
        dispatch(deviceUpdateRequest(payload._ids[0]));

        return fetch(`${ZZZAPI}/api/devices/locatoroperatingmode`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(payload)
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(deviceUpdateSuccess(json))
            });
    }
};

export const foundLocationsGetRequest = () => {
    return {
        type: FOUND_LOCATIONS_GET_REQUEST
    }
};

export const foundLocationsGetSuccess = (payload) => {
    return {
        type: FOUND_LOCATIONS_GET_SUCCESS,
        payload
    }
};

export const foundLocationsGetFailure = (payload) => {
    return {
        type: FOUND_LOCATIONS_GET_FAILURE,
        payload
    }
};

export const foundLocationsGet = (payload = {}) => {
    return (dispatch) => {
        dispatch(foundLocationsGetRequest());

        return fetch(`${ZZZAPI}/api/locatorfoundlocations`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    // 'Cache': 'no-cache'
                },
                credentials: 'include'
            })
            .then(response => response.json(),
                error => console.log('An error occurred: ', error))
            .then(json => {
                if(json.error) throw new Error(json.message);
                dispatch(foundLocationsGetSuccess(json))
            })
            .catch(err => dispatch(foundLocationsGetFailure(err.message)));
    }
};



const strings = {
    "en": {
        tracking: 'locate',
        Tracking: 'Locate',
        Normal: 'Normal',
        'Live Tracking': 'Locate Mode'
    }
};

const getLocaleString = (str, lang = 'en') => {
    if(!strings[lang]) return strings['en'][str];
    if(!strings[lang][str]) return str;

    return strings[lang][str];
};

export { getLocaleString };
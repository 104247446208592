import './locations.css';

import React, {useState, useLayoutEffect, useEffect} from 'react';
import {connect} from 'react-redux';
// import ReactDOMServer from "react-dom/server";
import {
    Container,
    Divider,
    Header,
    Grid,
    Segment,
    Embed,
    Label,
    Icon,
    List,
    Dimmer,
    Loader,
    Message,
    Menu,
    Button,
    Form,
    Table,
    Input
} from 'semantic-ui-react';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Map from '../map';
import {getLocaleString} from '../../languages/localised';
import {authenticate, verifyUser, addVerifiedDevice} from "../../actions/auth";
import {locationsGet, devicesGet, addressGet, previousLocationsGet, deviceSetOperMode, foundLocationsGet} from '../../actions/devices';
import {clearError} from "../../actions/user";
import BaseModal from '../base-modal';
import Countdown from '../countdown';
import GeneratePDF from '../../utils/pdf';

const Locations = (props) => {
    // const devicesGet = props.devicesGet;
    // const locationsGet = props.locationsGet;

    useLayoutEffect(() => {
        // const initStartDate = new Date(moment(new Date()).subtract(2,'d').format('YYYY-MM-DD'));
        // const initEndDate = new Date();
        //
        // const startDateQuery = moment(initStartDate).utc().format('YYYY-MM-DD');
        // const endDateQuery = moment(initEndDate).add(1, 'd').utc().format('YYYY-MM-DD');
        //
        // locationsGet({
        //     timezone: moment.tz.guess(),
        //     startDate: startDateQuery,
        //     endDate: endDateQuery
        // });
        // props.devicesGet();

        const payload = {
            timezone: moment.tz.guess(),
            tracking: true
        };

        props.locationsGet(payload);
    }, [props.devicesGet, props.locationsGet]);

    const [selected, setSelected] = useState(null);
    const [deviceAdded, setDeviceAdded] = useState(null);
    const [searchItems, setSearchItems] = useState(null);
    const [options, setOptions] = useState([]);
    const [locationsView, setLocationsView] = useState(props.devices.locationsView);
    const [view, setView] = useState([]);
    const [viewLoaded, setViewLoaded] = useState(false);
    const [centered, setCentered] = useState(true);
    const [checked, setChecked] = useState([]);
    const [mapType, setMapType] = useState('locations');
    const [disableMapUI, setDisableMapUI] = useState(false);
    const [searching, setSearching] = useState(false);
    const [query, setQuery] = useState({
        startDate: new Date(moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD')), // new Date(), // new Date(moment(new Date()).subtract(7,'d').format('YYYY-MM-DD')),
        endDate: new Date(),
        alertFilter: 'tracking',
        groups: []
    });
    const [liveTrack, setLiveTrack] = useState(null);
    const [modals, setModal] = useState({});
    const [test, setTest] = useState(0);
    const defaultForm = {
        email: {
            value: '',
            required: true,
            error: false
        },
        securityCode: {
            value: '',
            required: true,
            error: false
        },
        crimeRef: {
            value: '',
            required: false,
            error: false
        },
        userLoc: {
            value: '',
            required: false,
            error: false
        }
    };

    const [form, setForm] = useState(defaultForm);

    const setOperMode = () => {
        if (!selected) return;
        const device = props.devices.locationsView.find(device => device._id === selected);
        console.log(device);

        const payload = {
            operatingMode: 'Tracking',
            _ids: [selected],
            deviceAccountId: device && device.data && device.data.accountId,
        };

        props.deviceSetOperMode(payload);
    };

    const disabled = () => {
        let disabled = false;

        Object.keys(form).forEach(item => {
            if (form[item].required === true && form[item].value === '') {
                disabled = true;
            }
        });

        return disabled;
    };

    const deviceExists = (id) => {
        return id ? props.devices.locationsView[getDeviceLocationIndex(id)] : props.devices.locationsView[getDeviceLocationIndex(selected)] && (view && view.findIndex(loc => loc._id === selected) !== -1);
    };

    const getPreviousLocation = (id = null, payload = null) => {
        const startDateQuery = moment(query.startDate).utc().format('YYYY-MM-DD');
        const endDateQuery = moment(query.endDate).add(1, 'd').utc().format('YYYY-MM-DD');
        const deviceId = id ? id : deviceExists() && selected;

        if (deviceId) {
            props.previousLocationsGet(payload ? payload : {
                deviceId,
                timezone: moment.tz.guess(),
                startDate: startDateQuery,
                endDate: endDateQuery
            });
        }
    };

    const getFoundDevices = (id = null, payload = null) => {
        const startDateQuery = moment(query.startDate).utc().format('YYYY-MM-DD');
        const endDateQuery = moment(query.endDate).add(1, 'd').utc().format('YYYY-MM-DD');
        const deviceId = id ? id : deviceExists() && selected;

        props.foundLocationsGet();

        if (deviceId) {
            props.previousLocationsGet(payload ? payload : {
                deviceId,
                timezone: moment.tz.guess(),
                startDate: startDateQuery,
                endDate: endDateQuery
            });
        }
    };

    const getStatusColor = (device, idx = 0) => {
        // console.log(device);
        if (device.wakeups.length > 0 && device.wakeups[idx].locationMethod === 'gnss') return 'green';
        if (device.wakeups.length > 0 && device.wakeups[idx].locationMethod === 'lbs' && (device.wakeups[idx].latitude !== "" || device.wakeups[idx].signalStrength.substring(0, 3) !== '99,')) return 'yellow';
        return 'red';
    };

    const getBatteryStatus = (battery) => {
        let batteryObj;

        if (battery >= 75 && battery <= 100) batteryObj = {icon: 'battery full', color: 'green'};
        if (battery > 50 && battery < 75) batteryObj = {icon: 'battery three quarters', color: 'green'};
        if (battery > 25 && battery <= 50) batteryObj = {icon: 'battery half', color: 'yellow', fontColor: 'orange'};
        if (battery >= 10 && battery <= 25) batteryObj = {icon: 'battery low', color: 'yellow', fontColor: 'orange'};
        if (!battery || (battery >= 0 && battery < 10)) batteryObj = {icon: 'battery empty', color: 'red'};

        return <span style={{color: batteryObj.fontColor || batteryObj.color, fontSize: '10px'}}><Icon
            name={batteryObj.icon} color={batteryObj.color}/>{battery}%</span>
    };

    const LocationItem = (props) => (
        <List.Item key={props.device._id}
                   onClick={() => {
                       setChecked([]);
                       setChecked([props.device._id]);
                       setSelected(props.device._id);
                       setCentered(true);
                       if (mapType === 'previouslocations') {
                           getPreviousLocation(props.device._id);
                       }
                   }}
                   style={{border: props.device.data.stolenState === 2 ? 'dashed 3px green' : null}}
                   active={selected ? selected === props.device._id : props.firstId === props.device._id}
        >
            <List.Content
                onClick={() => {
                    setCentered(true);
                }}
            >
                <List.Header as='h4'>
                    <Label empty circular color={getStatusColor(props.device)}/> {props.device.name || props.device._id}
                </List.Header>
                <List.Content>
                    {getBatteryStatus(props.device.data.battery)} {props.device.wakeups && props.device.wakeups.length > 0 ? moment(props.device.wakeups[0].date).format('LLLL') : 'Unknown state.'}
                </List.Content>
            </List.Content>
            <Container style={{paddingTop: '5px'}}>
                {/*<Button basic fluid>*/}
                {/*    CRIME REF {props.device.data.crimeReference}*/}
                {/*</Button>*/}
                <Button.Group fluid >
                    <Button>Crime Ref  </Button>
                    <Button>{props.device.data.crimeReference}</Button>
                </Button.Group>
            </Container>
            <Container style={{paddingTop: '5px'}}>
                <Button.Group fluid basic>
                    <Button>Next Wakeup</Button>
                    <Button color='orange'><Countdown times={props.device.data.wakeupTimes}/></Button>
                </Button.Group>
            </Container>
            <Container style={{display: 'flex', paddingTop: '5px', flexDirection: 'row'}}>
                <Button fluid disabled={props.device.data.pendingOperatingMode === 'Tracking' && !props.device.data.tracked}
                        inverted color='red' onClick={setOperMode}
                        loading={props.isSettingMode === props.device._id}>
                    {props.device.data.tracked ? 'Extend Locate' : 'Locate'}
                </Button>
                <Button style={{margin: 0}} fluid
                        inverted={props.device.data.stolenState !== 2} color='green'
                >Found</Button>
            </Container>
        </List.Item>
    );

    const search = (e) => {
        setSearching(e.target.value.length > 0);
        const newItems = props.devices.locationsView.filter(device => device._id.toLowerCase().includes(e.target.value.toLowerCase()) || device.data.name.toLowerCase().includes(e.target.value.toLowerCase()));
        setSearchItems(newItems);
        const newView = mapType === 'tracking' ? newItems.filter((device, i) => device.data.tracked === true ? device : null) || [] : newItems || [];
        setView(newView);
        setOptions(newView.map((device, i) => {
            return <LocationItem key={device + i} device={device} firstId={props.devices.locationsView[0]._id}
                                 isSettingMode={props.devices.isUpdatingDevice}/>;
        }));
    };

    // Filter for devices in locate mode if map is tracking
    // const view = mapType === 'tracking' ? searchItems.filter((device, i) => device.data.tracked === true ? device : null) || [] : searchItems || [];

    // const options = view.map((device, i) => {
    //     return <LocationItem key={device + i} device={device} firstId={props.devices.locationsView[0]._id} isSettingMode={props.devices.isUpdatingDevice} />;
    // });

    const [pollingPaused, setPollingPaused] = useState(false);

    // Component will mount
    useEffect(() => {
        if(deviceAdded !== null && deviceExists(deviceAdded)) {
            getPreviousLocation(deviceAdded);
            setDeviceAdded(null);
        }

        if (query.alertFilter === 'tracking') {
            if (!liveTrack) {
                const payload = {
                    timezone: moment.tz.guess(),
                    tracking: true
                };

                props.locationsGet(payload);
                const liveTracker = setInterval(() => {
                    props.locationsGet(payload);
                }, 3000);

                setLiveTrack(liveTracker);
                setPollingPaused(false)
            }
        }

        if(mapType === 'found' && liveTrack) {
            clearInterval(liveTrack);
            setPollingPaused(true);
        }

        if(mapType !== 'found' && pollingPaused) {
            setInterval(liveTrack);
            setPollingPaused(false);
        }

        if (JSON.stringify(locationsView) !== JSON.stringify(props.devices.locationsView)) {
            const newLocationsView = props.devices.locationsView;

            // console.log('SEARCHING?', searching);

            setLocationsView(newLocationsView);

            const newSearchItems = searchItems && searching ? searchItems.filter(item => newLocationsView.find(device => device._id === item._id)).map(item => {
                const found = props.devices.locationsView.find(device => device._id === item._id);
                let match = false;

                if (found) {
                    match = JSON.stringify(found) === JSON.stringify(item);
                }

                return !match ? found : item;
            }) : newLocationsView;

            setSearchItems(newSearchItems);

            const newView = mapType === 'tracking' ? newSearchItems.filter((device, i) => device.data.tracked === true ? device : null) || [] : newSearchItems || [];
            setView(newView);
            setOptions(newView.map((device, i) => {
                return <LocationItem key={device + i} device={device} firstId={props.devices.locationsView[0]._id}
                                     isSettingMode={props.devices.isUpdatingDevice}/>;
            }));
        }

        // console.log('USE EFFECT: ', liveTrack, query.alertFilter);
        if (searchItems === null && locationsView.length > 0) {
            setSearchItems(locationsView);
        }

        if (!viewLoaded && props.devices.locationsView.length > 0) {
            const newLocationsView = props.devices.locationsView;
            const newView = mapType === 'tracking' ? newLocationsView.filter((device, i) => device.data.tracked === true ? device : null) || [] : newLocationsView || [];
            setView(newView);
            setOptions(newView.map((device, i) => {
                return <LocationItem key={device + i} device={device} firstId={props.devices.locationsView[0]._id}
                                     isSettingMode={props.devices.isUpdatingDevice}/>;
            }));
            setViewLoaded(true);
        }

        if (searchItems && (JSON.stringify(locationsView) !== JSON.stringify(props.devices.locationsView))) {
            const newSearchItems = searchItems.map(item => {
                const found = props.devices.locationsView.find(device => device._id === item._id);
                let match = false;

                if (found) {
                    match = JSON.stringify(found) === JSON.stringify(item);
                }
                ;

                return !match ? found : item;
            });

            if (!(JSON.stringify(newSearchItems) === JSON.stringify(searchItems))) {
                // setSearchItems(newSearchItems);
            }

            // if(viewLoaded) {
            //     const newView = mapType === 'tracking' ? newSearchItems.filter((device, i) => device.data.tracked === true ? device : null) || [] : newSearchItems || [];
            //     setView(newView);
            //     setOptions(newView.map((device, i) => {
            //         return <LocationItem key={device + i} device={device} firstId={props.devices.locationsView[0]._id} isSettingMode={props.devices.isUpdatingDevice} />;
            //     }));
            // }
        }

        // if(viewLoaded) {
        //     setView(mapType === 'tracking' ? searchItems.filter((device, i) => device.data.tracked === true ? device : null) || [] : searchItems || [])
        // }

        if (checked.length === 0 &&
            props.devices.locationsView.length > 0
        ) {
            setChecked([props.devices.locationsView[0]._id]);
        }

        if (!selected &&
            props.devices.locationsView.length > 0) {
            setSelected(props.devices.locationsView[0]._id)
        }

    }, [props, checked.length, query.alertFilter, liveTrack]);

    // Component will unmount
    useEffect(() => {
        return () => {
            if (liveTrack) clearInterval(liveTrack);
        };

    }, [liveTrack]);

    const DatePickerInput = ({value, onClick}) => (
        <Button basic icon labelPosition='right' onClick={onClick}>
            <Icon name='calendar alternate outline'/>{moment(value).local().format('DD/MM/YYYY')}
        </Button>
    );

    const change = (data) => {
        const updateQuery = {...query, ...data};
        const payload = {
            timezone: moment.tz.guess()
        };

        setQuery(updateQuery);

        if (updateQuery.deviceId) payload.deviceId = updateQuery.deviceId;
        if (updateQuery.startDate) payload.startDate = moment(updateQuery.startDate).utc().format('YYYY-MM-DD');
        if (updateQuery.endDate) payload.endDate = moment(updateQuery.endDate).add(1, 'd').utc().format('YYYY-MM-DD');
        if (updateQuery.alertFilter) payload[updateQuery.alertFilter] = true;
        if (updateQuery.groups && updateQuery.groups.length > 0) payload.groupsQuery = updateQuery.groups;

        if (mapType === 'previouslocations' && (updateQuery && updateQuery.deviceId)) {
            getPreviousLocation(updateQuery.deviceId, payload);
        }

        if (updateQuery.alertFilter !== 'tracking') {
            props.locationsGet(payload);
        } else {
            props.locationsGet(payload);
            const liveTracker = setInterval(() => {
                props.locationsGet(payload);
            }, 3000);

            setLiveTrack(liveTracker);
        }
    };

    const toggleMap = (type) => {
        change({alertFilter: type});
        if (type === 'previouslocations') {
            setChecked(checked.length > 0 ? [checked[0]] : []);
            getPreviousLocation()
        }

        if(type === 'found') {
            setChecked(checked.length > 0 ? [checked[0]] : []);
            getFoundDevices();
        }

        let newView = type === 'tracking' ? searchItems && searchItems.filter((device, i) => device.data.tracked === true ? device : null) || [] : searchItems || [];
        setView(newView);
        setOptions(newView.map((device, i) => {
            return <LocationItem key={device + i} device={device} firstId={props.devices.locationsView[0]._id}
                                 isSettingMode={props.devices.isUpdatingDevice}/>;
        }));


        setMapType(type);
    };

    const WakeItem = props => (
        <List.Item key={props.id}>
            <List.Content>
                <List.Header as='h4'>
                    <Label empty circular
                           color={getStatusColor(props.device, props.wakeupIdx)}/> {props.device.name || props.device._id}
                </List.Header>
                <List.Content>
                    {moment(props.device.wakeups[props.wakeupIdx].date).format('LLLL')}
                </List.Content>
                {props.device.wakeups[props.wakeupIdx].gnss && props.device.wakeups[props.wakeupIdx].gnss.GNSSStatus
                    ? <List.Content>
                        {props.device.wakeups[props.wakeupIdx].gnss.GNSSStatus}
                    </List.Content> : null}
            </List.Content>
        </List.Item>
    );

    const createWakeupLocation = (device, wakeupIdx, addIndex = false) => {
        const locationMethod = device.wakeups[wakeupIdx].locationMethod;
        const lat = device.wakeups[wakeupIdx][locationMethod].latitude;
        const lng = device.wakeups[wakeupIdx][locationMethod].longitude;
        const address = device.wakeups[wakeupIdx].address;
        const date = device.wakeups[wakeupIdx].date;
        const speed = locationMethod === 'gnss' ? device.wakeups[wakeupIdx][locationMethod].speed : null;
        const direction = locationMethod === 'gnss' ? device.wakeups[wakeupIdx][locationMethod].direction : null;
        const hdop = locationMethod === 'gnss' ? device.wakeups[wakeupIdx][locationMethod].GNSSStatus.split(',')[3] : null;
        const text = addIndex ? wakeupIdx.toString() : null;
        const tracking = device && device.data && device.data.tracked;
        const contentInfo = <WakeItem device={device} wakeupIdx={wakeupIdx}/>;
        const content = <div>{contentInfo}</div>;
        return {
            lat, lng, text, content, hdop, locationMethod, address, date, speed, direction, tracking
        };
    };

    const getChecked = check => {
        const idx = props.devices.locationsView.findIndex(loc => loc._id === check);
        if (idx === -1) return null;
        if (!props.devices.locationsView[idx] || props.devices.locationsView[idx].wakeups.length === 0) return null;
        return createWakeupLocation(props.devices.locationsView[idx], 0);
    };

    const getPreviousChecked = check => {
        if (props.devices.previousLocations && !props.devices.previousLocations[check]) return null;

        const previousLocations = [];
        const wakeupsLength = props.devices.previousLocations[check].wakeups.length;

        for (let i = 1; i < wakeupsLength; i++) {
            previousLocations.push(createWakeupLocation(props.devices.previousLocations[check], i, true));
        }

        return previousLocations;
    };

    const getMarkers = () => {
        let markers;
        if (mapType !== 'previouslocations') markers = selected && [selected].map(getChecked).filter(item => item !== null) || [];

        return markers && markers.length > 0 ? markers : [];
    };

    const getPreviousMarkers = () => {
        let markers;

        if (mapType === 'previouslocations') markers = selected && [selected].map(getPreviousChecked).filter(item => item !== null) || [];

        return markers && markers.length > 0 ? [].concat(...markers) : [];
    };

    const getDeviceLocationIndex = (deviceId) => {
        const idx = props.devices.locationsView.findIndex(item => item._id === deviceId);
        return idx !== -1 ? idx : 0;
    };

    const getDeviceDataValue = (deviceId, dataValue) => {
        const device = props.devices.locationsView.find(item => item._id === deviceId);
        return device && device.data && device.data[dataValue] ? device.data[dataValue] : '';
    };

    const getModeColor = (mode) => {
        const colors = {
            tracking: 'red',
            previouslocations: 'violet',
            locations: 'green',
            found: 'blue',
        };

        return colors[mode || 'locations'];
    };

    const onCloseModal = () => {
        props.clearError();
        setForm(defaultForm);
        setModal({});
    };
    const onOpenModal = (modalName) => {
        setModal({...modals, [modalName]: true});
    };

    const formChange = (e) => {
        const {value, maxLength} = e.target;
        const message = maxLength !== -1 ? value.slice(0, maxLength) : value;

        setForm({
            ...form,
            [e.target.name]: {
                ...form[e.target.name],
                value: message
            }
        });
    };

    const handleSubmit = () => {
        const obj = {
            email: form.email.value,
            securityCode: form.securityCode.value
        };

        // Object.keys(form).forEach((key, i) => {
        //     obj[key] = form[key].value;
        // });
        //
        // console.log(obj)

        props.verifyUser(obj);
    };

    const addDeviceToLocator = (_id, accountId) => {
        const payload = {
            _id,
            accountId,
            crimeRef: form.crimeRef.value,
            userLoc: form.userLoc.value
        };
        props.addVerifiedDevice(payload);
        setDeviceAdded(_id);
        props.clearError();
        setForm(defaultForm);
        setModal({});
        setChecked([_id._id]);
        setSelected(_id._id);
    };

    return (
        <Container fluid>

            <Container>
                <Divider hidden fitted/>

                <Header as='h1' textAlign='left'>
                    Locator Application
                </Header>

                <Segment color={getModeColor(mapType)} style={{padding: '0'}}>

                    <Grid columns={1} celled='internally' stackable>
                        <Grid.Column width={4}>
                            <Button fluid primary onClick={() => onOpenModal('verifyUser')}>Verify User</Button>
                            <Input style={{marginTop: '5px'}} fluid icon='search' placeholder='Search Device...'
                                   onChange={search}/>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Menu icon='labeled' size='tiny' fluid widths={3}>
                                <Menu.Item
                                    name='locations'
                                    active={mapType === 'locations'}
                                    color={mapType === 'locations' ? getModeColor(mapType) : null}
                                    onClick={() => toggleMap('locations')}
                                >
                                    <Icon name='location arrow'/>
                                    Current<br/>Location
                                </Menu.Item>
                                <Menu.Item
                                    name=''
                                    active={mapType === 'previouslocations'}
                                    color={mapType === 'previouslocations' ? getModeColor(mapType) : null}
                                    onClick={() => toggleMap('previouslocations')}
                                >
                                    <Icon name='history'/>
                                    Previous<br/>Locations
                                </Menu.Item>
                                <Menu.Item
                                    name='tracking'
                                    active={mapType === 'tracking'}
                                    color={mapType === 'tracking' ? getModeColor(mapType) : null}
                                    onClick={() => toggleMap('tracking')}
                                >
                                    <Icon name='search'/>
                                    Locate
                                </Menu.Item>
                                {/*<Menu.Item*/}
                                {/*    name='found'*/}
                                {/*    active={mapType === 'found'}*/}
                                {/*    color={mapType === 'found' ? getModeColor(mapType) : null}*/}
                                {/*    onClick={() => toggleMap('found')}*/}
                                {/*>*/}
                                {/*    <Icon name='check'/>*/}
                                {/*    Found*/}
                                {/*</Menu.Item>*/}
                            </Menu>
                        </Grid.Column>

                        <Grid.Column width={4} style={{
                            border: 'none',
                            boxShadow: 'none',
                            maxHeight: '710px',
                            overflowY: 'auto'
                        }}>
                            {(options.length === 0 && props.devices.locationsView > 0) || props.devices.isLoadingDevices ?
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer> :
                                <div>{options.length === 0 ?
                                    <Header as='h4' textAlign='center'>No Results</Header> :
                                    <List selection>
                                        {options}
                                    </List>}
                                </div>}
                        </Grid.Column>

                        <Grid.Column id='map-container2' width={12}>
                            <Embed active>
                                <MapContainer
                                    locations={view.length > 0 && view[getDeviceLocationIndex(selected)] ? view[getDeviceLocationIndex(selected)].wakeups[0] : {}}
                                    centered={centered}
                                    setCentered={setCentered}
                                    mapType={mapType}
                                    markers={view.length > 0 ? getMarkers() : []}
                                    previousLocations={deviceExists() && getPreviousMarkers() || []}
                                    isTracking={query.alertFilter === 'tracking'}
                                    disableMapUI={disableMapUI}
                                />
                            </Embed>

                            {deviceExists() ? <>
                                <Divider hidden fitted/>
                                {mapType === 'previouslocations' ? <Container textAlign='center'>
                                    <Button.Group>
                                        <DatePicker
                                            selected={query.startDate}
                                            onChange={(date) => change({startDate: date, deviceId: selected})}
                                            customInput={
                                                <DatePickerInput/>
                                            }
                                        />
                                        <Button.Or text='to'/>
                                        <DatePicker
                                            selected={query.endDate}
                                            onChange={(date) => change({endDate: date, deviceId: selected})}
                                            customInput={
                                                <DatePickerInput/>
                                            }
                                        />
                                    </Button.Group>{' '}
                                </Container> : null}

                                <Container fluid textAlign='center'>
                                    <Divider hidden fitted/>
                                    {/*<pre>{JSON.stringify(props.devices.devices, null, 2)}</pre>*/}
                                    <DetailsContainer
                                        getAddress={props.getAddress}
                                        isGettingAddress={props.devices.isGettingAddress}
                                        location={props.devices.locationsView[getDeviceLocationIndex(selected)]}
                                        operatingMode={getDeviceDataValue(selected, 'operatingMode')}
                                        pendingOperatingMode={getDeviceDataValue(selected, 'pendingOperatingMode')}
                                        previousLocations={mapType === 'previouslocations' ? getPreviousMarkers() : []}
                                        mapType={mapType}
                                        enableUI={() => setDisableMapUI(false)}
                                        disableUI={() => setDisableMapUI(true)}
                                    />
                                    <Divider hidden fitted/>
                                </Container>
                                {/*</Segment>*/}
                            </> : null}
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Divider hidden/>
                <Divider hidden/>
            </Container>

            <BaseModal
                color='blue'
                content='Verify User'
                icon='user'
                labelPosition='right'
                title='Verify User'
                open={!props.auth.verifyData && modals.verifyUser}
                onClose={onCloseModal}
            >
                {props.auth.verifyError ?
                    <Message
                        negative
                        header={props.auth.verifyError}
                    /> : null}
                <Form unstackable onSubmit={handleSubmit}>
                    <Form.Group widths='equal'>
                        <Form.Input
                            fluid
                            label='Crime Reference Number'
                            placeholder='Crime Reference Number'
                            type='text'
                            onChange={formChange}
                            name='crimeRef'
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input
                            fluid
                            label='User Location'
                            placeholder='User Location'
                            type='text'
                            onChange={formChange}
                            name='userLoc'
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input
                            type='number'
                            fluid
                            required
                            label='Security Code'
                            placeholder='****'
                            onChange={formChange}
                            name='securityCode'
                            error={form['securityCode'].error}
                            value={form['securityCode'].value}
                            maxLength='4'
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input
                            required
                            fluid
                            label='Email Address'
                            placeholder='Email Address'
                            type='text'
                            onChange={formChange}
                            name='email'
                            error={form['email'].error}
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Container textAlign='right'>
                            <Button color='blue' disabled={disabled()} type='submit'
                                    loading={props.auth.isVerifying}>Verify</Button>
                        </Container>
                    </Form.Group>
                </Form>
            </BaseModal>

            <BaseModal
                color='blue'
                content='User Verified!'
                icon='user'
                labelPosition='right'
                title='User Verified!'
                open={props.auth.verifyData && modals.verifyUser}
                onClose={onCloseModal}
            >
                {props.auth.verifyData ?
                    <Container fluid>
                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='user'/>
                                User Details
                            </Header>
                        </Divider>
                        <Table definition>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={4}>First Name</Table.Cell>
                                    <Table.Cell>{props.auth.verifyData.user.firstName || ''}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Last Name</Table.Cell>
                                    <Table.Cell>{props.auth.verifyData.user.lastName || ''}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Account</Table.Cell>
                                    <Table.Cell>{props.auth.verifyData.user.accountName || ''}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Email</Table.Cell>
                                    <Table.Cell>{props.auth.verifyData.user.email || ''}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Postcode</Table.Cell>
                                    <Table.Cell>{props.auth.verifyData.user.postcode || ''}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='location arrow'/>
                                Device Details
                            </Header>
                        </Divider>
                        <Table definition>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={4}>Device ID</Table.Cell>
                                    <Table.Cell>{props.auth.verifyData.device._id || ''}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Device Name</Table.Cell>
                                    <Table.Cell>{props.auth.verifyData.device.name || ''}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Recovery Code</Table.Cell>
                                    <Table.Cell>{props.auth.verifyData.device.recoveryCode || ''}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Container textAlign='right'>
                            <Button color='blue'
                                    onClick={() => addDeviceToLocator(props.auth.verifyData.device._id, props.auth.verifyData.user.accountId)}
                                    loading={props.auth.isAddingDevice}>Add Device</Button>
                            <Button color='grey' onClick={() => onCloseModal()}>Cancel</Button>
                        </Container>
                    </Container> : null}
            </BaseModal>
        </Container>
    );
};

function degToCompass(num) {
    var val = Math.floor((num / 22.5) + 0.5);
    var arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
    return arr[(val % 16)];
}

/**
 * DetailsContainer
 * @param props
 * @returns {*}
 * @constructor
 */
const DetailsContainer = (props) => {
    const [loading, setLoading] = useState(false);

    const getModeColor = (operatingMode) => {
        const colors = {
            Normal: '#3EBA45',
            Tracking: '#DB2828'
        };

        return colors[operatingMode] ? colors[operatingMode] : 'rgba(0,0,0,.35)';
    };

    const bgColour = getModeColor(props.operatingMode);
    const pendingBGColor = props.pendingOperatingMode ? getModeColor(props.pendingOperatingMode) : null;

    const createRow = (row) => {
        return <Table.Row key={`prevloc-${row.text}`}>
            <Table.Cell>{row.text}</Table.Cell>
            <Table.Cell>{moment(row.date).format('LLLL')}</Table.Cell>
            <Table.Cell data-lat={row.lat} data-lng={row.lng}>{row.address}</Table.Cell>
            <Table.Cell>{row.speed} MPH</Table.Cell>
            <Table.Cell>{degToCompass(row.direction)}</Table.Cell>
            <Table.Cell>{row.locationMethod.toUpperCase()}</Table.Cell>
        </Table.Row>
    };

    const createRows = (data) => {
        const rows = [];
        data.forEach(row => {
            rows.push(createRow(row))
        });

        return rows;
    };

    // const locationsDetails = <Container fluid>
    //         { props.location ?
    //                 <Grid columns={2}>
    //                     <Grid.Row></Grid.Row>
    //                     <Grid.Row>
    //                         <Grid.Column width={5}>
    //                             <ul style={{listStyleType: 'none', fontWeight: 'bold', color: 'darkGrey'}}>
    //                                 <li>ID:</li>
    //                                 <li>Connection Type:</li>
    //                                 <li>Last Operating Mode:</li>
    //                                 {props.location.wakeups && props.location.wakeups.length > 0 && props.location.wakeups[0].locationMethod === 'gnss' ? <li>Speed:</li> : null}
    //                                 {props.location.wakeups && props.location.wakeups.length > 0 && props.location.wakeups[0].locationMethod === 'gnss' ? <li>Direction:</li> : null}
    //                                 <li>Nearest Address:</li>
    //                             </ul>
    //                         </Grid.Column>
    //                         <Grid.Column width={10}>
    //                             <ul style={{listStyleType: 'none', marginTop: "15px", lineHeight: "1.5"}}>
    //                                 <li>{props.location._id}</li>
    //                                 <li>{props.location && props.location.wakeups && props.location.wakeups.length > 0 && props.location.wakeups[0].locationMethod ? props.location.wakeups[0].locationMethod.toUpperCase() : ''}</li>
    //                                 <li><span style={{ backgroundColor: bgColour, padding: '3px 3px', borderRadius: '10%', color: 'white', fontWeight: 'bold'}}>{getLocaleString(props.operatingMode)}</span></li>
    //                                 {props.location.wakeups && props.location.wakeups.length > 0 && props.location.wakeups[0].locationMethod === 'gnss' ? <li>{props.location.wakeups[0][props.location.wakeups[0].locationMethod].speed} MPH</li> : null}
    //                                 {props.location.wakeups && props.location.wakeups.length > 0 && props.location.wakeups[0].locationMethod === 'gnss' ? <li>{degToCompass(props.location.wakeups[0][props.location.wakeups[0].locationMethod].direction)}</li> : null}
    //                                 <li>{props.location.wakeups && props.location.wakeups.length > 0 && props.location.wakeups[0].address ? props.location.wakeups[0].address : ''}</li>
    //                             </ul>
    //                         </Grid.Column>
    //                     </Grid.Row>
    //                 </Grid>
    //             : <Header as='h4' textAlign='center'>No device selected.</Header>}
    //     </Container>;

    // console.log(props.location);

    const locationsDetails = props.location ?
        <Grid textAlign='center'>
            {/*{recoveryCodeView}*/}
            {props.location.wakeups && props.location.wakeups.length > 0 ? <Grid.Row>
                <Grid.Column width={12}>
                    <Table basic='very'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell collapsing>ID:</Table.Cell>
                                <Table.Cell collapsing>{props.location._id}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell collapsing>Connection Type:</Table.Cell>
                                <Table.Cell collapsing>
                                    {props.location && props.location.wakeups && props.location.wakeups[0].locationMethod ? props.location.wakeups[0].locationMethod.toUpperCase() : ''}
                                </Table.Cell>
                            </Table.Row>
                            {props.location.wakeups[0].locationMethod === 'gnss' ?
                                <Table.Row>
                                    <Table.Cell collapsing>
                                        Speed:
                                    </Table.Cell>
                                    <Table.Cell collapsing>
                                        {props.location.wakeups[0][props.location.wakeups[0].locationMethod].speed} MPH
                                    </Table.Cell>
                                </Table.Row> : null}
                            {props.location.wakeups[0].locationMethod === 'gnss' ?
                                <Table.Row>
                                    <Table.Cell collapsing>Direction:</Table.Cell>
                                    <Table.Cell collapsing>
                                        {degToCompass(props.location.wakeups[0][props.location.wakeups[0].locationMethod].direction)}
                                    </Table.Cell>
                                </Table.Row> : null}
                            <Table.Row>
                                <Table.Cell>Last Operating Mode</Table.Cell>
                                <Table.Cell>
                                    <span style={{
                                        backgroundColor: bgColour,
                                        padding: '3px 3px',
                                        borderRadius: '10%',
                                        color: 'white',
                                        fontWeight: 'bold'
                                    }}>{getLocaleString(props.operatingMode)}</span>
                                </Table.Cell>
                            </Table.Row>
                            {(props.pendingOperatingMode && props.pendingOperatingMode !== props.operatingMode) || (props.pendingOperatingMode === 'Tracking' && props.operatingMode === 'Tracking') ?
                                <Table.Row>
                                    <Table.Cell>Next Operating Mode</Table.Cell>
                                    <Table.Cell>
                                        <span style={{
                                            backgroundColor: pendingBGColor,
                                            padding: '3px 3px',
                                            borderRadius: '10%',
                                            color: 'white',
                                            fontWeight: 'bold'
                                        }}>{getLocaleString(props.pendingOperatingMode)}</span>
                                    </Table.Cell>
                                </Table.Row> : null}
                            <Table.Row>
                                <Table.Cell>Address:</Table.Cell>
                                <Table.Cell>
                                    {props.location.wakeups[0].address ? props.location.wakeups[0].address : ''}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <Divider hidden/>
                </Grid.Column>
            </Grid.Row> : <Grid.Row><Grid.Column><Divider hidden/><Divider hidden/><Header as='h4' textAlign='center'>No
                Data.</Header><Divider hidden/></Grid.Column></Grid.Row>}
        </Grid>
        : <Header as='h4' textAlign='center'>No device selected.</Header>;

    const input = document.getElementById('table');
    const map = document.getElementById('google-map');

    const previousLocationsDetails = <>
        <Button loading={loading} floated='right' onClick={() => {
            props.disableUI();
            setLoading(true);
            const callback = () => {
                setLoading(false);
                props.enableUI();
            };
            setTimeout(() => {
                GeneratePDF(input, {markup: map, callback}, props.location._id);

            }, 150);

        }} primary>Create Report</Button>

        <div>
            {props.previousLocations.length > 0 ?
                <Grid columns={2}>
                    <Grid.Row>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <ul style={{listStyleType: 'none', fontWeight: 'bold', color: 'darkGrey'}}>
                                <li>Device ID:</li>
                            </ul>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <ul style={{
                                listStyleType: 'none',
                                marginTop: "15px",
                                lineHeight: "1.5",
                                fontWeight: 'bold'
                            }}>
                                <li>{props.location._id}</li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                : <Header as='h4' textAlign='center'>
                    <Divider hidden/>
                    No results.
                </Header>}
            {props.previousLocations.length > 0 ?
                <Table striped id='table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Location</Table.HeaderCell>
                            <Table.HeaderCell>Date/Time</Table.HeaderCell>
                            <Table.HeaderCell>Address</Table.HeaderCell>
                            <Table.HeaderCell>Speed</Table.HeaderCell>
                            <Table.HeaderCell>Direction</Table.HeaderCell>
                            <Table.HeaderCell>Location Type</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {createRows(props.previousLocations)}
                    </Table.Body>
                </Table> : null}</div>
    </>;

    return props.mapType === 'previouslocations' ? previousLocationsDetails : locationsDetails;

};

let defaultCenter = {
    lat: 10,
    lng: 10
};

let success = position => {
    const currentPosition = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
    };
    defaultCenter = currentPosition;
};

const MapContainer = (props) => {
    const navigator = window.navigator;
    navigator.geolocation.getCurrentPosition(success);

    const [defaultProps] = useState({
        center: {
            lat: props.locations && Object.keys(props.locations).length > 0 ? props.locations[props.locations.locationMethod].latitude : defaultCenter.lat,
            lng: props.locations && Object.keys(props.locations).length > 0 ? props.locations[props.locations.locationMethod].longitude : defaultCenter.lng
        },
        zoom: 15
    });

    const locationsExist = props.locations && !!(Object.keys(props.locations).length > 0);

    return (
        <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}>
            <Map icon={Icon}
                // markers={[{lat: locationsExist && props.locations[props.locations.locationMethod].latitude, lng: locationsExist && props.locations[props.locations.locationMethod].longitude }]}
                 markers={props.markers}
                 previousLocations={props.previousLocations}
                 defaultZoom={defaultProps.zoom}
                 center={defaultProps.center}
                 lat={locationsExist ? props.locations[props.locations.locationMethod].latitude : defaultCenter.lat}
                 lng={locationsExist ? props.locations[props.locations.locationMethod].longitude : defaultCenter.lng}
                 centered={props.centered}
                 setCentered={props.setCentered}
                 mapType={props.mapType}
                 isTracking={props.isTracking}
                 disableMapUI={props.disableMapUI}
            />
        </div>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user,
        groups: state.groups,
        devices: state.devices
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (payload) => dispatch(authenticate(payload)),
        locationsGet: (payload) => dispatch(locationsGet(payload)),
        previousLocationsGet: (payload) => dispatch(previousLocationsGet(payload)),
        devicesGet: () => dispatch(devicesGet()),
        foundLocationsGet: () => dispatch(foundLocationsGet()),
        getAddress: (payload) => dispatch(addressGet(payload)),
        verifyUser: (payload) => dispatch(verifyUser(payload)),
        addVerifiedDevice: (payload) => dispatch(addVerifiedDevice(payload)),
        deviceSetOperMode: (payload) => dispatch(deviceSetOperMode(payload)),
        clearError: () => dispatch(clearError())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);

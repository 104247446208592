import React, {useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Menu, Flag } from 'semantic-ui-react';
import {logout} from '../../actions/auth';
import Cookies from "../../utils/cookies";

const MenuThemed = styled(Menu)`
  &&& {
    background-color: ${props => props.theme.colours.menuPrimary};
    color: ${props => props.theme.colours.menuTextPrimary};
  }
`;

const MenuLogo = styled.img`
  min-width: 90px;
`;

const MenuItemThemed = styled(Menu.Item)`
  &&& {
    color: ${props => props.theme.colours.menuTextPrimary};
    border-bottom: ${props => (props.selected === props.id) ? 'solid 2px' + props.theme.colours.menuTextPrimary : null};
    font-weight: bolder;
    font-size: small;
    align-items: flex-end !important;
  }
`;

const routes = {
    locations: 4
};

const MenuBar = (props) => {
    const pathname = props.location.pathname;
    useLayoutEffect(() => {
        setActiveItem(routes[pathname.substr(1)]);
    }, [pathname]);

    let [activeItem, setActiveItem ] = useState(0);

    const logout = (e) => {
        e.preventDefault();
        Cookies.delete('zzzuser');
        Cookies.delete('zzz');
        console.log('Logging Out');
        console.log(document.cookie);
        props.logout();
    };

    return props.auth.isAuthenticated ? (
        <Container fluid>
            <MenuThemed attached borderless compact>
                <Container>
                    <MenuItemThemed
                        id={0}
                        selected={1}
                    >
                        <MenuLogo src='/images/zzz_logo_black.svg' />
                    </MenuItemThemed>
                    <MenuThemed.Menu position='right'>
                        <MenuItemThemed
                            icon='user circle'
                            id={5}
                            selected={activeItem}
                            onClick={() => null}
                            name={`${props.user.firstName} ${props.user.lastName}`}
                        />
                    </MenuThemed.Menu>
                    <MenuThemed.Menu position='right'>
                        <MenuItemThemed
                            icon='power'
                            name='LOGOUT'
                            id={0}
                            selected={1}
                            onClick={logout}
                        />
                    </MenuThemed.Menu>
                </Container>
            </MenuThemed>
        </Container>
    ) : (
        <MenuThemed stackable attached borderless>
            <Container>
                <MenuItemThemed
                    id={0}
                    selected={1}
                >
                    <MenuLogo src='/images/zzz_logo_black.svg' />
                </MenuItemThemed>
                <MenuThemed.Menu position='right'>
                    <MenuItemThemed
                        id={0}
                        selected={1}
                    ><Flag name='uk' /></MenuItemThemed>
                </MenuThemed.Menu>
            </Container>
        </MenuThemed>
    )
};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        user: state.user
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuBar));

import {
    UI_ACTIONBUTTON_SET_ACTIONED,
    UI_ACTIONBUTTON_RESET_ACTIONED
} from '../actiontypes/types';

const initialState = {
    actionButton: {
        actioned: false
    }
};

export default (state = initialState, { type, payload}) => {
    switch(type) {
        case UI_ACTIONBUTTON_SET_ACTIONED:
            return {
                ...state,
                actionButton: {
                    actioned: true
                }
            };
        case UI_ACTIONBUTTON_RESET_ACTIONED:
            return {
                ...state,
                actionButton: {
                    actioned: false
                }
            };
        default: return state;
    }
};